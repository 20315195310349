import React from "react";
import {
  Container,
  Stack,
  Box,
  Typography,
  Grid,
  DialogContent,
  styled,
  DialogActions,
  colors,
} from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useState } from "react";
import {
  getPayrollHeads,
  getPayrollPageData,
  getStaffDetailByStaffDocId,
  getPayrollTransaction,
  updateMonthlyPayroll,
} from "app/services/hr";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import CustomLoader from "app/components/common/CustomLoader";
import LabelTextComponent from "app/components/common/LabelTextComponent";
import { IndianCurrency } from "app/utils/helper";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import ViewEmploymentDialog from "../EmploymentDialogs/ViewEmploymentDialog";
import appRoutes from "app/config/routes";
import AddNewDebtToStaffFreshDialog from "app/components/common/Dialogs/hr/payroll/AddNewDebtToStaffFreshDialog";
import {
  getPayrollSecurity,
  getStaffCalculatedDebt,
  updatePayrollAttendance,
} from "app/services/schoolService/hr.service";
import { Helmet } from "react-helmet";
import TableForEarningDeduction from "./TableForEarningDeduction";
import PayrollDrawer from "./PayrollDrawer";
import TableActionButtons from "app/components/common/TableActionButtons";
import { getMonthlyStaffAttendanceBySchool } from "app/services/reception";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { LoadingButton } from "@mui/lab";

import { Add } from "@mui/icons-material";
import SecurityMoneyDialog from "app/components/common/Dialogs/hr/payroll/SecurityMoneyDialog";
import SecurityTransactionDetail from "app/components/common/Dialogs/hr/payroll/SecurityTransactionDetail";
import DebtTransactionDetailDialog from "app/components/common/Dialogs/hr/payroll/DebtTransactionDetail";

const CreatePayroll = () => {
  const { id: payrollDocId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [debtState, setDebtState] = useState({
    withdrawalAmount: 0,
    depositAmount: 0,
    t_balance: 0,
  });
  const [falseState, setFalseState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    attendaceIndex: 0,
    openDrawer: false,
    drawerData: {},
    earningdHead: [],
    deductionsHead: [],
    data: {},
    keys: [],
    values: [],
    threeMonths: [],
    activeValues: {},
    activeTableData: {},
    activeHeadData: null,
    add: {},
    heads: [],
    selectedHead: {},
    transactions: [],
    updateTransaction: {},
    employmentInfo: {},
    earningData: [],
    deductionData: [],
    payrollHead: "",
    deletPayrollTransDocId: "",
    attendanceSummary: {},
    totalDays: "",
    presentDays: "",
    absentDays: "",
    halfDays: "",
    leaveDays: "",
    lopDay: "",
    holidays: "",
    attendanceDialog: false,
    flag: 0,
    debtDialog: false,
    securityMoneyDialog: false,
    securityTransactiDetailDialog: false,
    staffDebtTransactionDetail: false,
  });
  const [dialogs, setDialogs] = useState({
    updateAttendance: false,
    viewEmployment: false,
    updateEmployment: false,
  });
  const handleUpdateAttendance = () => {
    const isError = checkAttendanceDays();
    if (isError) return;
    updatePayrollAttendanceMutate({
      payrollDocId,
      totalDays: state.totalDays,
      presentDays: state.presentDays,
      holidays: state.holidays,
      leaveDays: state.leaveDays,
      halfDays: state.halfDays,
      lopDay: state.lopDay,
      absentDays: state.absentDays,
    });
  };

  const checkAttendanceDays = () => {
    const total =
      Number(state.presentDays) +
      Number(state.holidays) +
      Number(state.leaveDays) +
      Number(state.halfDays) +
      Number(state.lopDay) +
      Number(state.absentDays);
    if (total > Number(state.totalDays)) {
      return `-> Total Attendance ${total} days more than Total days ${Number(
        state.totalDays
      )}`;
    }
    if (total < Number(state.totalDays)) {
      return `-> Total Attendance ${total} days less than Total days ${Number(
        state.totalDays
      )}`;
    }

    return "";
  };
  const handleCloseAttendanceDialog = () => {
    const data = getPayrollData_Data?.data?.obj;
    setState((prev) => ({
      ...prev,
      attendanceDialog: false,
      totalDays: data?.totalDays,
      presentDays: data?.presentDays,
      absentDays: data?.absentDays,
      halfDays: data?.halfDays,
      leaveDays: data?.leaveDays,
      lopDay: data?.lopDay,
      holidays: data?.holidays,
    }));
  };
  const handleUpdatePayroll = async () => {
    const body = {
      payrollDocId: payrollDocId,
      totalEarnings: totalEarnings,
      totalDeductions: totalDeductions,
      netPay: Number(totalEarnings) - Number(totalDeductions),
    };

    updateMonthlyPayrollMutate(body);
  };
  const totalEarnings = state.earningData?.reduce(
    (sum, value) => sum + Number(value?.amount ?? 0),
    0
  );
  const totalDeductions = state.deductionData?.reduce(
    (sum, value) => sum + Number(value?.amount ?? 0),
    0
  );
  // mutate monthly payroll
  const {
    mutate: updateMonthlyPayrollMutate,
    isLoading: updateMonthlyPayrollLoading,
  } = useMutation(updateMonthlyPayroll, {
    onSuccess: (res) => {
      navigate(`/${appRoutes.payroll}?month=${searchParams.get("month")}`);
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  // update Attendance
  const {
    mutate: updatePayrollAttendanceMutate,
    isLoading: updatePayrollAttendanceLoading,
  } = useMutation({
    mutationKey: [""],
    mutationFn: updatePayrollAttendance,
    onSuccess: (sucess) => {
      setState((prev) => ({
        ...prev,
        attendanceDialog: false,
        totalDays: "",
        presentDays: "",
        absentDays: "",
        halfDays: "",
        leaveDays: "",
        lopDay: "",
        holidays: "",
      }));
      getMonthlyStaffAttendanceBySchoolRefetch();
      getPayrollDataRefetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  // get staff attendance
  const {
    isLoading: getMonthlyStaffAttendanceBySchoolLoading,
    isFetching: getMonthlyStaffAttendanceBySchoolFetching,
    refetch: getMonthlyStaffAttendanceBySchoolRefetch,
    data: getMonthlyStaffAttendanceBySchoolData,
  } = useQuery({
    queryKey: ["getMonthlyStaffAttendanceBySchool"],
    queryFn: () =>
      getMonthlyStaffAttendanceBySchool({
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
        year: state.data?.year,
        monthName: state.data?.month,
      }),
    onSuccess: (success) => {
      const summary = success?.data?.obj?.attendenceSummary;
      setState((prev) => ({
        ...prev,
        attendanceSummary: summary,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: state.data?.employmentInfo?.staffDetail?._id ? true : false,
  });

  // fetching payroll by Doc Id
  const {
    isLoading: getPayrollDataLoading,
    isFetching: getPayrollDataFetching,
    refetch: getPayrollDataRefetch,
    data: getPayrollData_Data,
  } = useQuery({
    queryKey: ["getPayrollData"],
    queryFn: () =>
      getPayrollPageData({
        payrollDocId,
      }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        data: res?.data?.obj,
        earningData: res?.data?.obj?.earnings,
        deductionData: res?.data?.obj?.deductions,
        totalDays: res?.data?.obj?.totalDays,
        presentDays: res?.data?.obj?.presentDays,
        absentDays: res?.data?.obj?.absentDays,
        halfDays: res?.data?.obj?.halfDays,
        leaveDays: res?.data?.obj?.leaveDays,
        lopDay: res?.data?.obj?.lopDay,
        holidays: res?.data?.obj?.holidays,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  // get All Payroll Head
  useQuery({
    queryKey: ["getPayrollHead"],
    queryFn: () => getPayrollHeads(),
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        earningdHead: success?.data?.list?.filter(
          (item) => item?.headType === "Earning"
        ),
        deductionsHead: success?.data?.list?.filter(
          (item) => item?.headType === "Deduction"
        ),
      }));
    },
  });
  // get payroll trasaction
  const {
    isLoading: getPayrollTransactionLoading,
    isFetching: getPayrollTransactionFetching,
    refetch: getPayrollTransactionRefetch,
  } = useQuery({
    queryKey: ["getPayrollTransaction"],
    queryFn: () =>
      getPayrollTransaction({
        month: state.data?.month,
        year: state.data?.year,
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
      }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        earningData: success?.data?.list?.filter(
          (item) => item?.headType === "Earning"
        ),
        deductionData: success?.data?.list?.filter(
          (item) => item?.headType === "Deduction"
        ),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
  });

  // get staff Debt detail
  const {
    isLoading: getStaffCalculatedDebtLoading,
    isFetching: getStaffCalculatedDebtFetching,
    refetch: getStaffCalculatedDebtRefetch,
    data: getStaffCalculatedDebtData,
  } = useQuery({
    queryKey: [
      "getStaffCalculatedDebt",
      state.data?.employmentInfo?.staffDetail?._id,
    ],
    queryFn: () =>
      getStaffCalculatedDebt({
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
      }),
    onSuccess: (success) => {
      setDebtState((prev) => ({
        ...prev,
        withdrawalAmount: success?.data?.withdrawalAmount,
        depositAmount: success?.data?.depositAmount,
        t_balance: success?.data?.t_balance,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: state.data?.employmentInfo?.staffDetail?._id ? true : false,
  });
  // get staff security
  const { refetch: getPayrollSecurityRefetch, data: getPayrollSecurityData } =
    useQuery({
      queryKey: [
        "getPayrollSecurity",
        state.data?.employmentInfo?.staffDetail?._id,
      ],
      queryFn: () =>
        getPayrollSecurity({
          staffDocId: state.data?.employmentInfo?.staffDetail?._id,
        }),
      onSuccess: (success) => {},
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: state.data?.employmentInfo?.staffDetail?._id ? true : false,
    });

  // Fetching employee information
  useQuery({
    queryKey: ["getEmployeeInfo"],
    queryFn: () =>
      getStaffDetailByStaffDocId(state.data?.employmentInfo?.staffDetail?._id),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, employmentInfo: res?.data?.obj }));
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: dialogs.viewEmployment ? true : false,
  });

  return (
    <>
      <Helmet>
        <title>Payroll | Create Payroll</title>
      </Helmet>
      <CustomLoader
        show={
          getPayrollDataLoading ||
          getPayrollDataFetching ||
          getStaffCalculatedDebtLoading ||
          getStaffCalculatedDebtFetching ||
          getPayrollTransactionLoading ||
          getPayrollTransactionFetching ||
          getMonthlyStaffAttendanceBySchoolLoading ||
          getMonthlyStaffAttendanceBySchoolFetching ||
          loading
        }
      />
      {/* transaction detail */}

      {/* security money dialog */}
      <SecurityTransactionDetail
        open={state.securityTransactiDetailDialog}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            securityTransactiDetailDialog: false,
          }))
        }
        list={getPayrollSecurityData?.data?.obj?.transactionHistory ?? []}
      />
      {/* security money dialog */}
      <SecurityMoneyDialog
        open={state.securityMoneyDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, securityMoneyDialog: false }))
        }
        refetch={getPayrollSecurityRefetch}
        data={{
          ...getPayrollSecurityData?.data?.obj,
          staffDocId: state.data?.employmentInfo?.staffDetail?._id,
        }}
      />
      {/* Debt dialog */}
      <DebtTransactionDetailDialog
        open={state.staffDebtTransactionDetail}
        onClose={() =>
          setState((prev) => ({ ...prev, staffDebtTransactionDetail: false }))
        }
        id={state.data?.employmentInfo?.staffDetail?._id}
      />
      {/* Debt dialog */}
      <AddNewDebtToStaffFreshDialog
        open={state.debtDialog}
        onClose={() => setState((prev) => ({ ...prev, debtDialog: false }))}
        refetch={() => {
          getStaffCalculatedDebtRefetch();
          getPayrollTransactionRefetch();
        }}
        staffDetail={{
          _id: {
            staffDocId: state.data?.employmentInfo?.staffDetail?._id,
          },
        }}
      />
      {/* Drawer  */}
      <PayrollDrawer
        open={state.openDrawer}
        handleClose={() => {
          setState((prev) => ({
            ...prev,
            openDrawer: false,
          }));
        }}
        data={getMonthlyStaffAttendanceBySchoolData?.data?.obj}
        staffData={state?.data?.employmentInfo}
      />
      {/* update staff attendance dialog */}
      <CommonDialog
        open={state.attendanceDialog}
        title="Update Attendance"
        minWidth="600px"
        onClose={handleCloseAttendanceDialog}
      >
        <DialogContent>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={6}>
              <TextFieldLabel title="Total Days" />
              <CustomTextFieldDisabled
                size="small"
                fullWidth
                type="number"
                value={state?.totalDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    totalDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Present Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.presentDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    presentDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Absent Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.absentDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    absentDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Half Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.halfDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    halfDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Leave Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.leaveDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    leaveDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="LOP Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.lopDay}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    lopDay: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Holidays" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.holidays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    holidays: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
          <Typography
            sx={{
              color: colors.red[500],
              fontSize: "14px",
              fontWeight: 500,
              mt: 2,
            }}
          >
            {checkAttendanceDays()}
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={updatePayrollAttendanceLoading}
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleUpdateAttendance}
            sx={{ fontWeight: 600, textTransform: "capitalize" }}
          >
            Change Record
          </LoadingButton>
        </DialogActions>
      </CommonDialog>

      {/* View employment dialog */}
      <ViewEmploymentDialog
        handleCloseView={() =>
          setDialogs((prev) => ({ ...prev, viewEmployment: false }))
        }
        viewOpen={dialogs.viewEmployment}
        staffDocId={state?.data?.employmentInfo?.staffDetail?._id}
        refetch={getPayrollDataRefetch}
      />

      <Container maxWidth="xl">
        <Title>Staff Information</Title>
        <CustomBox style={{ padding: "20px" }}>
          <Grid container spacing={1} columns={10}>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Employee Id"
                text={state?.data?.employmentInfo?.employeeId}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography fontSize={14} color="primary" sx={{ opacity: 0.75 }}>
                Name
              </Typography>
              <LinkText
                to={`/${appRoutes.staffProfile}/${state?.data?.employmentInfo?.staffDetail?._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${state?.data?.employmentInfo?.staffDetail?.firstName} ${state?.data?.employmentInfo?.staffDetail?.middleName} ${state?.data?.employmentInfo?.staffDetail?.lastName}` ||
                  "--"}{" "}
              </LinkText>
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Department"
                text={state?.data?.employmentInfo?.departmentName}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Designation"
                text={state?.data?.employmentInfo?.designation}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="PAN Number"
                text={state?.data?.employmentInfo?.staffDetail?.PAN}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="EPF Number"
                text={state?.data?.employmentInfo?.epfNumber}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="EPS Number"
                text={state?.data?.employmentInfo?.epsNumber}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Basic salary"
                text={state?.data?.employmentInfo?.basicSalary}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Salary / Day"
                text={state?.data?.employmentInfo?.salaryPerDay}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Salary Month-Year"
                text={state?.data?.month + "-" + state?.data?.year}
              />
            </Grid>
          </Grid>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Typography
              className="link"
              onClick={() =>
                setDialogs((prev) => ({ ...prev, viewEmployment: true }))
              }
            >
              View Employment
            </Typography>
          </Stack>
        </CustomBox>
        <Stack direction={"row"} gap={2} mt={1}>
          <Box>
            <Title>Advance Salary Information</Title>
            <CustomBox sx={{ width: "fit-content" }}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={4}
              >
                <Stack direction={"row"} alignItems={"center"} gap={4}>
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                      Total Debt / Loan
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: colors.red[600],
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          staffDebtTransactionDetail: true,
                        }))
                      }
                    >
                      {IndianCurrency(debtState.withdrawalAmount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Total Adjusted
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: colors.green[400],
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          staffDebtTransactionDetail: true,
                        }))
                      }
                    >
                      {IndianCurrency(debtState.depositAmount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                      Total Balance
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: colors.blue[600],
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          staffDebtTransactionDetail: true,
                        }))
                      }
                    >
                      {IndianCurrency(
                        Number(debtState.depositAmount ?? 0) +
                          Number(debtState.withdrawalAmount ?? 0)
                      )}
                    </Typography>
                  </Box>
                </Stack>
                <Box
                  sx={{
                    background: "#fefefe",
                    height: "20px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setState((prev) => ({ ...prev, debtDialog: true }))
                  }
                >
                  <Add />
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={4}
                mt={1}
              >
                <Stack direction={"row"} alignItems={"center"} gap={4}>
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                      Security Money
                    </Typography>
                    <CustomLinktext
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          securityTransactiDetailDialog: true,
                        }))
                      }
                    >
                      {IndianCurrency(
                        getPayrollSecurityData?.data?.obj?.securityMoney
                      )}
                    </CustomLinktext>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                      Security Remarks
                    </Typography>

                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      {getPayrollSecurityData?.data?.obj?.remarks}
                    </Typography>
                  </Box>
                </Stack>
                <Box>
                  <img
                    alt="edit"
                    src="/edit-icon.png"
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        securityMoneyDialog: true,
                      }))
                    }
                    style={{
                      height: "25px",
                      width: "25px",

                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Stack>
            </CustomBox>
          </Box>
          <Box>
            <Title>
              Attendance Info For {state.data?.month}-{state.data?.year}
              <span
                style={{ marginLeft: "10px" }}
                className="link"
                onClick={() =>
                  setState((prev) => ({ ...prev, openDrawer: true }))
                }
              >
                view
              </span>
            </Title>
            <CustomBox sx={{ width: "fit-content" }}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Box>
                  <Grid container columns={14} spacing={2} sx={{ m: 0, p: 0 }}>
                    <Grid xs={4} />

                    <Grid xs={2}>
                      <Typography className="sub-title">Holiday</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title">Present</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title">
                        Absent + LOP
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title">Half Day</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title">Leave</Typography>
                    </Grid>

                    <Grid xs={4}>
                      <Typography className="sub-title-content">
                        As per Record :
                      </Typography>
                    </Grid>

                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state.attendanceSummary?.holidays}
                      </Typography>
                    </Grid>

                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state.attendanceSummary?.presentDays}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state.attendanceSummary?.absentDays} +{" "}
                        {state.attendanceSummary?.lopDay}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state.attendanceSummary?.halfDays}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state.attendanceSummary?.leaveDays}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    columns={14}
                    spacing={2}
                    sx={{ m: 0, p: 0, mt: 1 }}
                  >
                    <Grid xs={4}>
                      <Typography className="sub-title-content">
                        Arbitrary :
                      </Typography>
                    </Grid>

                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state?.holidays}
                      </Typography>
                    </Grid>

                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state?.presentDays}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state?.absentDays} + {state?.lopDay}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state?.halfDays}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="sub-title-content">
                        {state?.leaveDays}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <TableActionButtons
                  showEdit
                  handleEdit={() =>
                    setState((prev) => ({ ...prev, attendanceDialog: true }))
                  }
                />
              </Stack>
            </CustomBox>
          </Box>
        </Stack>

        {/* earining or deduction */}

        <Box>
          <Title>Earnings</Title>
          <TableForEarningDeduction
            getStaffCalculatedDebtData={getStaffCalculatedDebtData?.data}
            setDebtState={setDebtState}
            setFalseState={setFalseState}
            tableData={state.earningData}
            headType="Earning"
            refetch={() => {
              getPayrollTransactionRefetch();
              getStaffCalculatedDebtRefetch();
            }}
            staffDocId={state.data?.employmentInfo?.staffDetail?._id}
            payrollHead={state.earningdHead}
            total={IndianCurrency(totalEarnings)}
            totalText="Total Earning"
            month={state?.data?.month}
            year={state?.data?.year}
            status={state.data?.status}
          />
        </Box>

        <Box mt={2}>
          <Title>Deductions</Title>
          <TableForEarningDeduction
            getStaffCalculatedDebtData={getStaffCalculatedDebtData?.data}
            setDebtState={setDebtState}
            setFalseState={setFalseState}
            tableData={state.deductionData}
            headType="Deduction"
            refetch={() => {
              getPayrollTransactionRefetch();
              getStaffCalculatedDebtRefetch();
            }}
            staffDocId={state.data?.employmentInfo?.staffDetail?._id}
            payrollHead={state.deductionsHead}
            total={IndianCurrency(totalDeductions)}
            totalText="Total Deduction"
            month={state?.data?.month}
            year={state?.data?.year}
          />
        </Box>
        <CustomBox
          style={{
            display: "flex",
            marginTop: "15px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title sx={{ my: 1 }}>Net Pay</Title>
          <Title sx={{ my: 1 }}>Total Earning - Total Deduction</Title>

          <Title sx={{ my: 1 }}>
            {IndianCurrency(Number(totalEarnings) - Number(totalDeductions))} /-
          </Title>
        </CustomBox>
        <Stack justifyContent={"space-between"} margin={2} direction={"row"}>
          <Typography>
            Note : These below detail is showing as per last updated Employment
            Info
          </Typography>
          <LoadingButton
            loading={updateMonthlyPayrollLoading}
            onClick={() => handleUpdatePayroll()}
            variant="contained"
            color="secondary"
            sx={{ fontSize: "1.2rem", padding: "10px 30px" }}
          >
            Update
          </LoadingButton>
        </Stack>
      </Container>
    </>
  );
};

export default CreatePayroll;

const CustomBox = styled("div")`
  /* background: rgba(208, 208, 226, 0.08); */
  background: #fff;
  /* border: 1px solid #d0d0e2; */
  /* border-radius: 15px; */
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
  .sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
    opacity: 0.75;
  }
  .title {
  }
  .sub-title-content {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #0c2f49;
  margin-bottom: 5px;
  margin-left: 10px;
  .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
`;
const LinkText = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  color: blue;
`;
const CustomLinktext = styled(Typography)`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;
