import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  MenuItem,
  InputAdornment,
  Divider,
  styled,
  useMediaQuery,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import uploadFile from "app/utils/uploadFile";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  addBookExpenseDetail,
  addNewBookSeller,
  getBookSellerNames,
} from "app/services/ledgerAccount";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { DeleteOutline } from "@mui/icons-material";
import { Distrct, States } from "app/config/administrativeConstants";
import { LoadingButton } from "@mui/lab";
import CommonDialog from "../../CommonDialog";
const initialValues = {
  class: "",
  section: "",
  bookTitle: "",
  sections: [],
  qty: 0,
  bRate: 0,
  sRate: 0,
  studentArr: [
    {
      class: "",
      section: "",
      bookTitle: "",
      sections: [],
      qty: 0,
      bRate: 0,
      sRate: 0,
    },
  ],
};
const BuyBookDialog = ({
  classData = [],
  refetch = () => {},
  open = false,
  onClose,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [seller, setSeller] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    tableData: [],
    selectedClass: "",
    selectedSeller: "",
    customize: {
      class: "",
      section: "",
      gName: "",
      bookList: [],
    },
    image: {
      link: "",
      file: null,
    },
    section: "",
    bookQuantity: 0,
    bookQuantityDialog: false,
    bookDocId: "",
  });
  const buyBookFormik = useFormik({
    initialValues,
    validationSchema: yup.object({
      studentArr: yup.array().of(
        yup.object({
          class: yup.mixed().required("required"),

          bookTitle: yup.string().required("required"),
          qty: yup.number().required("required"),
          bRate: yup.number().required("required"),
          sRate: yup.number().required("required"),
        })
      ),
    }),
    onSubmit: (values) => {
      values.studentArr?.length > 0
        ? handleUploadServer(values)
        : toast.error("Please Add Atleast One Book Information");
    },
  });
  const bookSellerFormik = useFormik({
    initialValues: {
      firmName: "",
      mobileNumber: "",
      alternateMobileNumber: "",
      address: "",
      dist: "",
      state: "",
      pinCode: "",
    },
    validationSchema: yup.object({
      firmName: yup.string().required("Book Seller Name is required"),
      mobileNumber: yup
        .string()
        .required("Mobile Number is required")
        .matches(/^[0-9]{10}$/, {
          message: "Mobile Number must be 10 digits",
        }),
      alternateMobileNumber: yup.string().matches(/^[0-9]{10}$/, {
        message: "Mobile Number must be 10 digits",
      }),
      address: yup.string().required("Address is required"),
      dist: yup.string().required("District is required"),
      state: yup.string().required("State is required"),
      pinCode: yup
        .string()
        .required("Pin Code is required")
        .matches(/^[0-9]{6}$/, {
          message: "Pin Code must be 6 digits",
        }),
    }),
    onSubmit: (values) => {
      const obj = {
        firmName: values.firmName,
        mobileNumber: values.mobileNumber,
        alternateMobileNumber: values.alternateMobileNumber,
        permanentAddress: {
          address: values.address,
          dist: values.dist,
          state: values.state,
          pinCode: values.pinCode,
        },
      };
      addNewBookSellerMutate(obj);
    },
  });
  const handleUploadServer = async (values) => {
    setLoading(true);
    try {
      let attachmentUrl = "";
      if (state?.image?.file) {
        let s3FilePath = `book/${state?.image?.file?.name}`;
        attachmentUrl = await uploadFile(
          state?.image?.file,
          s3FilePath,
          state?.image?.file?.type
        );
      }
      addBookExpenseDetailMutate({
        bookSellerDocId: seller,
        attachementUrl: attachmentUrl ? attachmentUrl.fileURL : "",
        bookArray: values.studentArr?.map((item) => ({
          classDocId: item?.class,

          bookTitle: item?.bookTitle,
          quantity: item?.qty,
          sellingRate: item?.sRate,
          buyingRate: item?.bRate,
        })),
      });

      setSeller("");
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  const handleUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      image: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };
  const handleClose = () => {
    buyBookFormik.setValues({
      class: "",
      section: "",
      bookTitle: "",
      sections: [],
      qty: 0,
      bRate: 0,
      sRate: 0,
      studentArr: [
        {
          class: "",
          section: "",
          bookTitle: "",
          sections: [],
          qty: 0,
          bRate: 0,
          sRate: 0,
        },
      ],
    });
    bookSellerFormik.resetForm();
    setSeller("");
    setState((prev) => ({
      ...prev,
      image: {
        file: null,
        link: "",
      },
    }));
    onClose();
  };
  const {
    mutate: addBookExpenseDetailMutate,
    isLoading: addBookExpenseDetailLoading,
  } = useMutation(addBookExpenseDetail, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      refetch();
      handleClose();
    },

    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const filteredData = state?.sellers?.find((item) => item?._id === seller);
  const sections = (data) => {
    return classData?.filter((item) => item.classDocId === data)[0]?.sections;
  };
  const { refetch: getBookSellers } = useQuery({
    queryKey: "getBookSellers",
    queryFn: getBookSellerNames,
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, sellers: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: open ? true : false,
  });
  const { mutate: addNewBookSellerMutate, isLoading: addNewBookSellerLoading } =
    useMutation(addNewBookSeller, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        getBookSellers();
        bookSellerFormik.resetForm();
        setSeller("");
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const getError = (name, index) => {
    try {
      if (
        buyBookFormik.touched?.studentArr[index][name] &&
        Boolean(buyBookFormik.errors?.studentArr[index][name])
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const getTouched = (name, index) => {
    try {
      if (buyBookFormik.touched?.studentArr[index][name]) {
        return buyBookFormik.errors?.studentArr[index][name];
      }
    } catch (error) {
      return "";
    }
  };
  const districts = Distrct[States.indexOf(bookSellerFormik.values.state) + 1];

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="700px"
        title="Buy Book"
      >
        <DialogContent>
          {seller !== "other" && (
            <Stack sx={{ pt: 1 }}>
              <form id="buyBookForm" onSubmit={buyBookFormik.handleSubmit}>
                <GrayBox>
                  <Stack px={2} py={0.5}>
                    <Title sx={{ fontWeight: "500" }}>Book Seller Detail</Title>
                  </Stack>
                  <Divider
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                    }}
                  />
                  <Grid container spacing={1} sx={{ py: 1 }}>
                    <Grid item xs={12} md={3.5}>
                      <TextFieldLabel
                        styleProps={{ fontSize: isMobile && "12px" }}
                        title="Select Seller Name"
                        required
                      />
                      <CustomSelectBox
                        native
                        size="small"
                        fullWidth
                        displayEmpty
                        value={seller}
                        onChange={(e) => setSeller(e.target.value)}
                      >
                        <MenuItem value="" disabled component="option">
                          Select
                        </MenuItem>
                        {state?.sellers?.map((item) => (
                          <MenuItem value={item?._id} component="option">
                            {item.firmName}
                          </MenuItem>
                        ))}
                        <MenuItem value="other" component="option">
                          Other
                        </MenuItem>
                      </CustomSelectBox>
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                      <Stack>
                        <Label sx={{ fontWeight: 500 }}>Mobile Number</Label>
                        <Label>{filteredData?.mobileNumber || "--"}</Label>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                      <Stack>
                        <Label sx={{ fontWeight: 500 }}>
                          Alternate Mobile No
                        </Label>
                        <Label>
                          {filteredData?.alternateMobileNumber || "--"}
                        </Label>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Label sx={{ fontWeight: 500 }}>Seller Address</Label>
                      <Label>
                        {filteredData?.firmAddress?.address ?? ""},{" "}
                        {filteredData?.firmAddress?.dist ?? ""},{" "}
                        {filteredData?.firmAddress?.state ?? ""},{" "}
                        {filteredData?.firmAddress?.pinCode ?? ""}
                      </Label>
                    </Grid>
                  </Grid>
                </GrayBox>
                <GrayBox mt={1} sx={{ width: "100%", overflowX: "auto" }}>
                  <Box p={1} sx={{ width: { xs: "550px", md: "100%" } }}>
                    {buyBookFormik.values.studentArr?.map((item, index) => (
                      <Stack key={index}>
                        <Grid container rowSpacing={2} columnSpacing={1}>
                          <Grid item xs={2}>
                            <Label>Sr. No.</Label>
                            <Label>{index + 1}</Label>
                          </Grid>
                          <Grid item xs={5}>
                            <Label>Class Stream</Label>
                            <CustomSelectBox
                              native
                              MenuProps={MenuProps}
                              displayEmpty
                              fullWidth
                              size="small"
                              value={item.class}
                              onChange={(e) => {
                                item["class"] = e.target.value;
                                item["section"] = "";
                                item["sections"] = sections(e.target.value);
                                buyBookFormik.setValues((prev) => ({
                                  ...prev,
                                }));
                              }}
                            >
                              <MenuItem value="" disabled component="option">
                                Select Class
                              </MenuItem>
                              {classData?.map((item) => (
                                <MenuItem
                                  value={item.classDocId}
                                  component="option"
                                >
                                  {item?.className_stream}
                                </MenuItem>
                              ))}
                            </CustomSelectBox>
                            <Typography sx={{ color: "red" }}>
                              {getError("class", index)}
                            </Typography>
                            <Typography sx={{ color: "red" }}>
                              {getTouched("class", index)}
                            </Typography>
                          </Grid>

                          <Grid item xs={5}>
                            <Label>Book title</Label>
                            <CustomTextField
                              placeholder="Enter Book title"
                              value={item.bookTitle}
                              fullWidth
                              size="small"
                              onChange={(e) => {
                                item["bookTitle"] = e.target.value;
                                buyBookFormik.setValues((prev) => ({
                                  ...prev,
                                }));
                              }}
                            />
                            <Typography sx={{ color: "red" }}>
                              {getError("bookTitle", index)}
                            </Typography>
                            <Typography sx={{ color: "red" }}>
                              {getTouched("bookTitle", index)}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Label>Enter Qty</Label>
                            <CustomTextField
                              type="number"
                              value={item.qty}
                              fullWidth
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography>pcs</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  item["qty"] = e.target.value;
                                  buyBookFormik.setValues((prev) => ({
                                    ...prev,
                                  }));
                                }
                              }}
                            />
                            <Typography sx={{ color: "red" }}>
                              {getError("qty", index)}
                            </Typography>
                            <Typography sx={{ color: "red" }}>
                              {getTouched("qty", index)}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Label>Buying Rate</Label>
                            <CustomTextField
                              type="number"
                              value={item.bRate}
                              fullWidth
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography>&#8377;</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  item["bRate"] = e.target.value;
                                  buyBookFormik.setValues((prev) => ({
                                    ...prev,
                                  }));
                                }
                              }}
                            />
                            <Typography sx={{ color: "red" }}>
                              {getError("bRate", index)}
                            </Typography>
                            <Typography sx={{ color: "red" }}>
                              {getTouched("bRate", index)}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Label>Selling Rate</Label>
                            <CustomTextField
                              type="number"
                              value={item.sRate}
                              fullWidth
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography>&#8377;</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  item["sRate"] = e.target.value;
                                  buyBookFormik.setValues((prev) => ({
                                    ...prev,
                                  }));
                                }
                              }}
                            />
                            <Typography sx={{ color: "red" }}>
                              {getError("sRate", index)}
                            </Typography>
                            <Typography sx={{ color: "red" }}>
                              {getTouched("sRate", index)}
                            </Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Label>Amount</Label>
                            <Label>
                              {IndianCurrency(item?.qty * item?.bRate)}
                            </Label>
                          </Grid>
                          <Grid item xs={0.5}>
                            {buyBookFormik.values.studentArr?.length > 1 && (
                              <IconButton
                                sx={{ mt: 3 }}
                                onClick={() => {
                                  buyBookFormik.setValues((prev) => ({
                                    ...prev,
                                    studentArr: prev.studentArr?.filter(
                                      (item, i) => i !== index
                                    ),
                                  }));
                                }}
                              >
                                <DeleteOutline sx={{ color: "red" }} />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                        {buyBookFormik.values.studentArr?.length !==
                          index + 1 && (
                          <Divider sx={{ background: "#000", my: 1 }} />
                        )}
                      </Stack>
                    ))}
                    <Typography
                      onClick={() => {
                        buyBookFormik.setValues((prev) => ({
                          ...prev,
                          studentArr: [
                            ...prev.studentArr,
                            {
                              class: prev.studentArr[0]?.class,
                              section: prev.studentArr[0]?.section,
                              bookTitle: "",
                              sections: prev.studentArr[0]?.sections,
                              qty: 0,
                              bRate: 0,
                              sRate: 0,
                            },
                          ],
                        }));
                      }}
                      sx={{
                        color: "#2789FD",
                        cursor: "pointer",
                        mt: 2,
                        display: "inline",
                      }}
                    >
                      + Add More
                    </Typography>
                  </Box>
                </GrayBox>
                <Stack p={2}>
                  <Label>Update Attachment (if any)</Label>
                  <Box>
                    <img
                      src={
                        state?.image?.link
                          ? state?.image?.link
                          : "/image-placeholder.jpeg"
                      }
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      alt="ekalsutra"
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      component="label"
                    >
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        hidden
                        onChange={handleUpload}
                      />
                      Upload
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Stack>
          )}
          {seller === "other" && (
            <Stack p={2}>
              <form id="addSellerForm" onSubmit={bookSellerFormik.handleSubmit}>
                <GrayBox>
                  <Stack px={2} py={1} marginTop={1}>
                    <Title sx={{ fontWeight: "500" }}>Book Seller Detail</Title>
                  </Stack>
                  <hr />
                  <Stack p={2}>
                    <Stack direction={isMobile ? "column" : "row"} gap={2}>
                      <Stack flex={1}>
                        <Label>Select Seller Name</Label>
                        <CustomSelectBox
                          native
                          value={seller}
                          onChange={(e) => setSeller(e.target.value)}
                          sx={{ width: "100%" }}
                        >
                          <MenuItem value="" disabled component="option">
                            Select
                          </MenuItem>
                          {state?.sellers?.map((item) => (
                            <MenuItem value={item} component="option">
                              {item.firmName}
                            </MenuItem>
                          ))}
                          <MenuItem value="other" component="option">
                            Other
                          </MenuItem>
                        </CustomSelectBox>
                      </Stack>
                      <Stack flex={2}>
                        <Label>Book Seller Name</Label>
                        <CustomTextField
                          placeholder="Enter Book Seller Name"
                          name="firmName"
                          onChange={bookSellerFormik.handleChange}
                        />
                        {bookSellerFormik.errors.firmName &&
                          bookSellerFormik.touched.firmName && (
                            <Typography sx={{ color: "red" }}>
                              {bookSellerFormik.errors.firmName}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>

                    <Stack
                      direction={isMobile ? "column" : "row"}
                      gap={2}
                      mt={2}
                    >
                      <Stack flex={1}>
                        <Label>Mobile number</Label>
                        <CustomTextField
                          name="mobileNumber"
                          placeholder="Enter Mobile Number"
                          type="number"
                          onChange={(e) => {
                            if (e.target.value.length < 11) {
                              bookSellerFormik.setValues((prev) => ({
                                ...prev,
                                mobileNumber: e.target.value,
                              }));
                            }
                          }}
                          value={bookSellerFormik.values.mobileNumber}
                        />
                        {bookSellerFormik.errors.mobileNumber &&
                          bookSellerFormik.touched.mobileNumber && (
                            <Typography sx={{ color: "red" }}>
                              {bookSellerFormik.errors.mobileNumber}
                            </Typography>
                          )}
                      </Stack>
                      <Stack flex={1}>
                        <Label>Alternate Mobile number</Label>
                        <CustomTextField
                          placeholder="Enter Alt Mobile Number"
                          name="alternateMobileNumber"
                          value={bookSellerFormik.values.alternateMobileNumber}
                          type="number"
                          onChange={(e) => {
                            if (e.target.value.length < 11) {
                              bookSellerFormik.setValues((prev) => ({
                                ...prev,
                                alternateMobileNumber: e.target.value,
                              }));
                            }
                          }}
                        />
                        {bookSellerFormik.errors.alternateMobileNumber &&
                          bookSellerFormik.touched.alternateMobileNumber && (
                            <Typography sx={{ color: "red" }}>
                              {bookSellerFormik.errors.alternateMobileNumber}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>

                    <Stack direction="row" mt={2}>
                      <Stack flex={1}>
                        <Label>Seller address</Label>
                        <CustomTextField
                          placeholder="Enter Seller Address"
                          name="address"
                          onChange={bookSellerFormik.handleChange}
                          value={bookSellerFormik.values.address}
                        />
                        {bookSellerFormik.errors.address &&
                          bookSellerFormik.touched.address && (
                            <Typography sx={{ color: "red" }}>
                              {bookSellerFormik.errors.address}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>

                    <Stack
                      direction={isMobile ? "column" : "row"}
                      gap={2}
                      mt={2}
                      flexWrap={isMobile && "wrap"}
                    >
                      <Stack flex={1}>
                        <Label>Select State</Label>
                        <CustomSelectBox
                          native
                          displayEmpty
                          MenuProps={MenuProps}
                          sx={{ width: "100%" }}
                          name="state"
                          value={bookSellerFormik.values.state}
                          onChange={(e) => {
                            bookSellerFormik.setValues((prev) => ({
                              ...prev,
                              state: e.target.value,
                              dist: "",
                            }));
                          }}
                        >
                          <MenuItem value="" disabled component="option">
                            Select
                          </MenuItem>
                          {States.map((state) => (
                            <MenuItem value={state} component="option">
                              {state}
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                        {bookSellerFormik.errors.state &&
                          bookSellerFormik.touched.state && (
                            <Typography sx={{ color: "red" }}>
                              {bookSellerFormik.errors.state}
                            </Typography>
                          )}
                      </Stack>
                      <Stack flex={1}>
                        <Label>Select District</Label>
                        <CustomSelectBox
                          native
                          displayEmpty
                          MenuProps={MenuProps}
                          sx={{ width: "100%" }}
                          name="dist"
                          value={bookSellerFormik.values.dist}
                          disabled={!bookSellerFormik.values.state}
                          onChange={bookSellerFormik.handleChange}
                        >
                          <MenuItem value="" disabled component="option">
                            Select
                          </MenuItem>
                          {districts.length !== 1 &&
                            districts?.map((dist) => (
                              <MenuItem value={dist} component="option">
                                {dist}
                              </MenuItem>
                            ))}
                        </CustomSelectBox>

                        {bookSellerFormik.errors.dist &&
                          bookSellerFormik.touched.dist && (
                            <Typography sx={{ color: "red" }}>
                              {bookSellerFormik.errors.dist}
                            </Typography>
                          )}
                      </Stack>
                      <Stack flex={1}>
                        <Label>Enter Pincode</Label>
                        <CustomTextField
                          placeholder="Enter Pincode"
                          name="pinCode"
                          value={bookSellerFormik.values.pinCode}
                          type="number"
                          onChange={(e) => {
                            if (e.target.value.length < 7) {
                              bookSellerFormik.setValues((prev) => ({
                                ...prev,
                                pinCode: e.target.value,
                              }));
                            }
                          }}
                        />
                        {bookSellerFormik.errors.pinCode &&
                          bookSellerFormik.touched.pinCode && (
                            <Typography sx={{ color: "red" }}>
                              {bookSellerFormik.errors.pinCode}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>
                  </Stack>
                </GrayBox>
              </form>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {seller === "other" ? (
            <LoadingButton
              form="addSellerForm"
              loading={addNewBookSellerLoading}
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
            >
              Submit
            </LoadingButton>
          ) : (
            <LoadingButton
              disabled={!seller}
              form={"buyBookForm"}
              loading={addBookExpenseDetailLoading || loading}
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
            >
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default BuyBookDialog;
const GrayBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#f8fbff",
  border: "1px solid rgba(61, 57, 137, 0.1)",
  boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.05)",
  borderRadius: "20px",
  padding: "10px",
}));
const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.4rem",
  fontWeight: 600,
  color: "#0C2F49",
}));
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 600,
  color: "#0C2F49",
}));
