import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Helmet } from "react-helmet";
import JoinApproval from "./JoinApproval";
import ProfileApproval from "./ProfileApproval";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Container } from "@mui/material";

const JoiningApproval = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Joining Approval</title>
      </Helmet>

      <TabContext value={value}>
        <TabsWrapper>
          <TabList onChange={handleChange}>
            <Tab label="Joining Approval" value="1" />
            <Tab label="Profile Approval" value="2" />
          </TabList>
        </TabsWrapper>
        <TabPanel value="1" sx={{ p: "0 !important" }}>
          <Box sx={{ my: 2 }}>
            <JoinApproval />
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ p: "0 !important" }}>
          <Box sx={{ my: 2 }}>
            <ProfileApproval />
          </Box>
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default JoiningApproval;
