import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, Grid } from "@mui/material";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { updateBookRate } from "app/services/ledgerAccount";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

const UpdateBookRateDialog = ({ open, handleClose, refetch, data }) => {
  const editFormik = useFormik({
    initialValues: {
      buyingRate: "",
      sellingRate: "",
    },
    validationSchema: yup.object({
      buyingRate: yup.number().required("buyingRate is required"),
      sellingRate: yup.number().required("sellingRate is required"),
    }),
    onSubmit: (values) => {
      updateBookRateMutate({
        bookDocId: data?._id,
        buyingRate: values.buyingRate,
        sellingRate: values.sellingRate,
      });
    },
  });

  const {
    mutate: updateBookRateMutate,
    isLoading: updateBookRateMutateLoading,
  } = useMutation(updateBookRate, {
    onSuccess: (success) => {
      refetch();
      handleClose();
      toast.success(success?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  useEffect(() => {
    editFormik.setValues({
      buyingRate: data?.buyingRate,
      sellingRate: data?.sellingRate,
    });
  }, [open]);
  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="400px"
        title="Update Book Rate"
      >
        <form onSubmit={editFormik.handleSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextFieldLabel title="Buying Rate" />
                <CustomTextField
                  size="small"
                  fullWidth
                  name="buyingRate"
                  value={editFormik?.values.buyingRate}
                  onChange={editFormik?.handleChange}
                  placeholder="type buying Rate"
                  type="number"
                  error={
                    Boolean(editFormik?.errors.buyingRate) &&
                    editFormik?.touched.buyingRate
                  }
                  helperText={
                    editFormik?.touched.buyingRate &&
                    editFormik?.errors.buyingRate
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Selling Rate" />
                <CustomTextField
                  size="small"
                  fullWidth
                  name="sellingRate"
                  value={editFormik?.values.sellingRate}
                  onChange={editFormik?.handleChange}
                  placeholder="type Selling rate"
                  type="number"
                  error={
                    Boolean(editFormik?.errors.sellingRate) &&
                    editFormik?.touched.sellingRate
                  }
                  helperText={
                    editFormik?.touched.sellingRate &&
                    editFormik?.errors.sellingRate
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={updateBookRateMutateLoading}
            >
              Update
            </LoadingButton>
          </DialogActions>
        </form>
      </CommonDialog>
    </>
  );
};

export default UpdateBookRateDialog;
