import React from "react";
import CommonDialog from "../../../CommonDialog";

import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { useMutation } from "react-query";
import { updateBookGroupName } from "app/services/schoolService/ledgerNaccounts.service";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { Grid, Stack } from "@mui/material";

const EditBookGroupNameDialog = ({
  open,
  onClose,
  existingBookGroupNameData,
  refetch,
}) => {
  const [bookGroupName, setBookGroupName] = React.useState("");
  const handleClose = () => {
    onClose();
  };

  const {
    mutate: updateBookGroupNameMutate,
    isLoading: updateBookGroupNameLoading,
  } = useMutation("updateBookGroupName", updateBookGroupName, {
    onSuccess: (success) => {
      refetch();
      handleClose();
      toast.success(
        success?.data?.message ?? "Book Group Name Updated Successfully"
      );
    },
    onError: (err) => {
      toast.error(
        err?.response?.data?.message ?? "Failed to Update Book Group Name"
      );
    },
    onSettled: () => {
      setBookGroupName("");
    },
  });

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="700px"
        title="Edit Book Group Name"
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "20px",
          }}
        >
          <Grid item xs={6}>
            <TextFieldLabel title={"Existing Book Group Name"} />
            <CustomTextFieldDisabled
              fullWidth
              value={existingBookGroupNameData?.bookGroupName ?? ""}
              size="medium"
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel title={"New Book Group Name"} />
            <CustomTextField
              fullWidth
              value={bookGroupName}
              onChange={(e) => setBookGroupName(e.target.value)}
              size="medium"
              placeholder="Enter New Book Group Name"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={2}
          sx={{ padding: "20px" }}
        >
          <LoadingButton
            color="primary"
            variant="contained"
            size="medium"
            type="submit"
            disabled={!bookGroupName}
            onClick={() => {
              updateBookGroupNameMutate({
                bookGroupDocId: existingBookGroupNameData?._id,
                bookGroupName: bookGroupName,
              });
            }}
            loading={updateBookGroupNameLoading}
          >
            Update
          </LoadingButton>
        </Stack>
      </CommonDialog>
    </>
  );
};

export default EditBookGroupNameDialog;
