import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
  colors,
  styled,
} from "@mui/material";
import AddNewSessionDialog from "app/components/common/Dialogs/school/AddNewSessionDialog";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { selectActiveSession, selectSelectedSchool } from "../../selectors";
import { useQuery } from "react-query";
import { getAllSessionOrderBySchool } from "app/services/orgService/school.service";
import { toast } from "react-toastify";
import { getAllSessions } from "app/services/management";
import OrderDetailDialog from "app/components/common/Dialogs/school/OrderDetailDialog";
import TransactionDetailDialog from "app/components/common/Dialogs/school/TransactionDetail";
import RazorPayPaymentDialog from "app/components/common/Dialogs/school/RazorPayPaymentDialog";

const Transaction = () => {
  const activeSession = useSelector(selectActiveSession);
  const schoolDetails = useSelector(selectSelectedSchool);
  const [state, setState] = useState({
    addSessionDialog: false,
    session: "",
    data: [],
    summary: {},
    orderDetailDialog: false,
    orderDetailData: {},
    transactionDetailDialog: false,
    collectPaymentDialog: false,
    sessionOrderDocId: "",
    transactionList: [],
  });
  const calculateTobePaid = () => {
    const filteredList = state.data?.filter((item) => item?.checked);
    const total = filteredList.reduce((total, currentValue) => {
      const tobePaid =
        Number(currentValue?.netOrderAmount ?? 0) -
        Number(currentValue?.paidAmount ?? 0) -
        Number(currentValue?.totalExtraDiscount ?? 0);
      return total + tobePaid;
    }, 0);

    return total;
  };
  const {
    isLoading: getAllSessionOrderBySchoolLoading,
    isFetching: getAllSessionOrderBySchoolFetching,
    refetch: getAllSessionOrderBySchoolRefetch,
  } = useQuery({
    queryKey: ["getAllSessionOrderBySchool", state.session],
    queryFn: () => getAllSessionOrderBySchool({ session: state.session }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        data: success?.data?.list,
        summary: success?.data?.summary,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.session ? true : false,
  });
  const { data: sessionsResponse } = useQuery({
    queryKey: ["getAllSessions"],
    queryFn: () => getAllSessions(),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  useEffect(() => {
    setState((prev) => ({ ...prev, session: activeSession }));
  }, []);

  return (
    <>
      <Helmet>
        <title>Transaction</title>
      </Helmet>

      {/* payment using razorpay */}
      <RazorPayPaymentDialog
        open={state.collectPaymentDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, collectPaymentDialog: false }))
        }
        data={state.data?.filter((item) => item?.checked) || []}
        schoolDocId={schoolDetails?._id}
        session={state.session}
        refetch={() => getAllSessionOrderBySchoolRefetch()}
      />

      {/* transaction detail dialog */}
      <TransactionDetailDialog
        open={state.transactionDetailDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, transactionDetailDialog: false }))
        }
        id={state.sessionOrderDocId}
        transactionList={state.transactionList}
      />
      {/* view order detail */}
      <OrderDetailDialog
        open={state.orderDetailDialog}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            orderDetailDialog: false,
            orderDetailData: {},
          }))
        }
        data={state.orderDetailData}
      />
      {/* Add Session Dialog */}
      <AddNewSessionDialog
        open={state.addSessionDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, addSessionDialog: false }))
        }
        schoolDocId={""}
      />
      <Wrapper>
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* Select and View */}
          <Stack direction={"row"} gap={2}>
            <CustomSelectBox
              MenuProps={MenuProps}
              size="large"
              sx={{ width: "207px" }}
              displayEmpty
              value={state.session}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {sessionsResponse?.data?.list?.map((item, idx) => (
                <MenuItem
                  value={item?.sessionName}
                  key={idx}
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      session: item?.sessionName,
                    }))
                  }
                >
                  {item?.sessionName} Std. Count-{item?.studentLimitCount}
                </MenuItem>
              ))}
            </CustomSelectBox>
            <LoadingButton
              loading={
                getAllSessionOrderBySchoolLoading ||
                getAllSessionOrderBySchoolFetching
              }
              variant="contained"
              color="secondary"
              onClick={() => getAllSessionOrderBySchoolRefetch()}
              disabled={!Boolean(state.session)}
            >
              View
            </LoadingButton>
            <Typography className="student_limit">
              <span style={{ color: "#777" }}>Student Limit:</span>{" "}
              <span style={{ fontWeight: 700, fontSize: "22px", color: "red" }}>
                {
                  sessionsResponse?.data?.list?.find(
                    (item) => item?.sessionName === state.session
                  )?.studentLimitCount
                }
              </span>{" "}
              {}
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={3} alignItems={"center"}>
            <Button
              className="add-new-session"
              variant="outlined"
              size="small"
              color="secondary"
              sx={{
                background: "white",
                "&:hover": {
                  background: "white",
                },
              }}
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  addSessionDialog: true,
                }));
              }}
            >
              + ADD NEW SESSION
            </Button>
            {/* <Stack className="download_license">
              <Link to={"/"}>
                <Typography color="secondary" className="license_content">
                  Download License
                </Typography>
              </Link>
            </Stack> */}
          </Stack>
        </Stack>
        <Grid container spacing={2} sx={{ mt: "1rem" }}>
          <Grid item xs={3.5}>
            <Stack className="grid_payment">
              <Typography className="verification_status">
                Verification Status :
              </Typography>
              <Typography
                className="verification_status_content"
                sx={{
                  color: schoolDetails?.verified ? "#35ab35" : "#ff0d02",
                }}
              >
                {schoolDetails?.verified ? "True" : "False"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3.5}>
            <Stack className="grid_payment">
              <Typography className="payment_cleared">
                Account Status :
              </Typography>
              <Typography
                className="account_status_content"
                sx={{ textTransform: "capitalize" }}
              >
                {schoolDetails?.accountStatus ?? "--"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3.5}>
            <Stack className="grid_payment">
              <Typography className="payment_cleared">
                Subscription Type:
              </Typography>
              <Typography
                className="payment_cleared_content"
                sx={{
                  color:
                    schoolDetails?.subscriptionType === "Advance"
                      ? "#00ac30"
                      : schoolDetails?.subscriptionType === "Basic"
                      ? "#2789FD"
                      : schoolDetails?.subscriptionType === "Free"
                      ? "#0C2F49"
                      : "#ECA820",
                }}
              >
                {schoolDetails?.subscriptionType ?? "--"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        {state.data?.length > 0 && (
          <>
            <OrderListTable>
              <Box className="top_header">
                <Grid container spacing={1} columns={15}>
                  <Grid item xs={3}>
                    <Typography className="header_text">
                      Product Type : Description
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="header_text">Due Date</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="header_text">Amount</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="header_text">Discount</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="header_text">Paid</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className="header_text">To Be Paid</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="header_text">Status</Typography>
                  </Grid>
                </Grid>
              </Box>
              {state.data?.map((item, i) => (
                <div key={i}>
                  <Grid
                    container
                    spacing={1}
                    columns={15}
                    sx={{ px: "5px", py: "4px" }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Grid item xs={3}>
                      <Typography
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            orderDetailData: item,
                            orderDetailDialog: true,
                          }))
                        }
                        className="value_text"
                        sx={{
                          color: "rgba(39, 137, 253, 1) !important",
                          cursor: "pointer",
                        }}
                      >
                        {item?.productDetail?.productType}-
                        {item?.productDetail?.productName}-
                        {item?.productDetail?.productDescription}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value_text">
                        {dayjs(item?.dueDate).format("DD MMM, YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value_text">
                        {IndianCurrency(item?.orderAmount)}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography className="value_text">
                        {IndianCurrency(
                          Number(item?.discountAtpurchage ?? 0) +
                            Number(item?.totalExtraDiscount ?? 0)
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            sessionOrderDocId: item?._id,
                            transactionDetailDialog: true,
                            transactionList: item?.transactions,
                          }))
                        }
                        className="value_text"
                        sx={{
                          color: "rgba(39, 137, 253, 1) !important",
                          cursor: "pointer",
                        }}
                      >
                        {IndianCurrency(Number(item?.paidAmount ?? 0))}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                        {Number(item?.netOrderAmount ?? 0) -
                          Number(item?.paidAmount ?? 0) -
                          Number(item?.totalExtraDiscount ?? 0) !==
                          0 &&
                          ["Not Paid", "REJECTED"].includes(item?.status) && (
                            <Checkbox
                              checked={item?.checked ? true : false}
                              color="secondary"
                              onChange={(e) => {
                                item.checked = !item.checked;
                                setState((prev) => ({ ...prev }));
                              }}
                            />
                          )}
                        <Typography
                          className="value_text"
                          sx={{
                            color: "rgba(39, 137, 253, 1) !important",
                            cursor: "pointer",
                          }}
                        >
                          {IndianCurrency(
                            Number(item?.netOrderAmount ?? 0) -
                              Number(item?.paidAmount ?? 0) -
                              Number(item?.totalExtraDiscount ?? 0)
                          )}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        className="value_text"
                        sx={{
                          color:
                            item?.status === "Paid"
                              ? "rgba(20, 219, 28, 1) !important"
                              : item?.status === "REJECTED" ||
                                item?.status === "Not Paid"
                              ? "rgba(255, 0, 0, 1) !important"
                              : "rgba(251, 177, 34, 1) !important",
                        }}
                      >
                        {item?.status}
                      </Typography>
                    </Grid>
                  </Grid>
                  {state.sessionProduct?.list?.length !== i + 1 && (
                    <Divider sx={{ backgroundColor: "#B6C8D6" }} />
                  )}
                </div>
              ))}
            </OrderListTable>
            <Grid container spacing={1} columns={13}>
              <Grid item xs={9} />
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: colors.blue[500],
                    fontWeight: 600,
                  }}
                >
                  Added Amount : {IndianCurrency(calculateTobePaid())}
                </Typography>
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <SummaryBox>
                <Stack direction={"row"} gap={2}>
                  {/*  Total Order Amount */}
                  <Box>
                    <Typography className="summary_label">
                      Total Order Amount
                    </Typography>
                    <Typography className="summary_value">
                      {IndianCurrency(state?.summary?.t_orderAmount)}
                    </Typography>
                  </Box>

                  {/*  */}
                  <Box>
                    <Typography className="summary_label">
                      Total Discount
                    </Typography>
                    <Typography className="summary_value">
                      {IndianCurrency(
                        Number(state?.summary?.t_discountAtpurchage ?? 0) +
                          Number(state?.summary?.t_extraDiscountAmount ?? 0)
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="summary_label">
                      Paid Amount
                    </Typography>
                    <Typography className="summary_value">
                      {IndianCurrency(state?.summary?.t_taxableAmount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="summary_label">
                      To Be Paid
                    </Typography>
                    <Typography className="summary_value">
                      {IndianCurrency(state?.summary?.t_balanceAmount)}
                    </Typography>
                  </Box>
                </Stack>
              </SummaryBox>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                sx={{ background: "#fff", borderRadius: "10px" }}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    collectPaymentDialog: true,
                  }))
                }
              >
                Pay
              </Button>
            </Stack>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Transaction;
const SummaryBox = styled("div")`
  border-radius: 10px;
  border: 1px solid rgba(27, 50, 60, 0.05);
  background: rgba(43, 81, 177, 0.05);
  padding: 10px 18px;
  margin-top: 15px;
  display: inline-block;
  .summary_label {
    color: rgba(27, 50, 60, 0.75);
    font-size: 14px;
    font-weight: 400;
  }
  .summary_value {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
`;
const OrderListTable = styled("div")`
  border-radius: 12px;
  border: 1px solid #b6c8d6;
  background-color: #fff;
  margin-top: 20px;
  .top_header {
    border-radius: 12px 12px 0px 0px;
    background-color: #b6c8d644;
    padding: 10px 5px;
  }
  .header_text {
    color: #0c2f49;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.48px;
  }
  .value_text {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
`;
const Wrapper = styled("div")`
  .license {
    color: #0c2f49;
    font-size: 16px;
    font-weight: 400;
  }
  .license_content {
    color: #0c2f49;
    font-size: 16px;
    font-weight: 600;
  }
  .payment_cleared {
    color: #0c2f49;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.6px;
  }
  .payment_cleared_content {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.6px;
  }
  .grid_payment {
    border-radius: 15px;
    border: 1px solid #b6c8d6;
    background: #fff;
    padding: 1rem;
  }
  .verification_status {
    color: #0c2f49;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.6px;
  }
  .verification_status_content {
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.6px;
  }
  .account_status_content {
    color: #35ab35;
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-transform: capitalize;
  }
  .student_box {
    border-radius: 15px;
    border: 1px solid rgba(89, 172, 24, 0.2);
    background: rgba(89, 172, 24, 0.05);
  }
  .student_limit {
    color: #0c2f49;

    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: auto 0;
  }
  .amount_box {
    border-radius: 15px;
    border: 1px solid rgba(39, 137, 253, 0.2);
    background: rgba(39, 137, 253, 0.1);
  }
  .total_amount {
    color: #0c2f49;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.6px;
  }
  .registration {
    color: #0c2f49;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
  }
  .download_license {
    border-radius: 15px;
    border: 1px solid #b6c8d6;
    background: #fff;
    align-items: center;
    padding: 10px;
  }
  .license_content {
  }
  .total_amount_description {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 600;
  }
  .total_amount_description_content {
    color: #0c2f49;
    /* text-align: right; */
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.48px;
  }
  .heading {
    color: #0c2f49;
    font-size: 20px;
    font-weight: 700;
  }
  .heading2 {
    color: #0c2f49;
    font-size: 20px;
    font-weight: 700;
    /* margin-top: 2rem; */
  }
  .paidAmount {
    color: #5bce00;
    font-size: 16px;
    font-weight: 700;
  }
  .balanceAmount {
    color: #f10f0f;
    font-size: 16px;
    font-weight: 700;
  }
  .add-new-session {
    padding: 0;
    width: 189px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #2789fd;
    background: #fff;
    color: #2789fd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
`;
