import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Box, Container } from "@mui/material";
import BookSummary from "./BookSummary";
import IssueBook from "./SaleBook";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import BookGroup from "./BookGroup";

const BooksPage = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Container maxWidth="xl">
        <TabContext value={value}>
          <TabsWrapper>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Book Summary" value="1" />
              <Tab label="Sale Book" value="2" />
              <Tab label="Books Group" value="3" />
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important" }}>
            <Box sx={{ mt: 1 }}>
              <BookSummary />
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ p: "0 !important" }}>
            <Box sx={{ mt: 1 }}>
              <IssueBook />
            </Box>
          </TabPanel>
          <TabPanel value="3" sx={{ p: "0 !important" }}>
            <Box sx={{ mt: 1 }}>
              <BookGroup />
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </LocalizationProvider>
  );
};

export default BooksPage;
